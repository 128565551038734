import React from 'react';
const About = () => {

  return (
      <div className="container-fluid" style={{marginTop: '-52px', backgroundColor: "#f9f9f9"}}>
  <div className="container-fluid">
    <div className="row mt-4">
      <div className="col-12">
        <h2 className="title text-center text-dark pt-5 fw-bolder">ABOUT US</h2>
        <p className="text-center fw-bolder">The structure of CRAN CAPITAL Platform’s products consists of the following services:</p>
      </div>
      <div className="col-md-6">
        <div className="upper-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
        
          <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
            <strong>CRAN FIXED INCOME</strong> <br />
            CRAN CAPITAL fixed income portfolio is a tool for establishing a steady flow of a fixed income. FIP is a portfolio formed from all investment products of CRAN CAPITAL DeFi Platform. Portfolio consists of Staking, Mining and Invest Portfolio. The user gets the opportunity to receive the maximally high income with minimal risks. We are teetering this portfolio not only on falling within cryptocurrencies, but also on products.
          </p>
          <p className="text mt-2 text-dark text-left p-2 lh-md fs-6 text-justify">
            <strong>CRAN CAPITAL STAKING</strong> <br />
            CRAN CAPITAL Staking is a storage process (holding) of means in the wallet for receiving remuneration. This tool gives an opportunity to get the estimated income as simple as possible. You don’t need to execute the transactions or carry out another difficult tasks. User may get the highest possible profit under the same level of risks.
          </p>
        </div>
      </div>
      <div className="col-md-6 text-center">
        <div className="p-1 text-center" style={{marginTop: 80, boxShadow: 'rgba(255, 255, 255) 0px 5px 10px 0px', borderRadius: 10, transform: 'translate(0px, -80px)'}}>
          <img style={{}} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659960622/investbaner.jpg" alt="about" className="img-fluid" />
        </div>
        <p className="text text-dark text-left p-2 lh-md fs-6 text-justify" style={{marginTop: "-90px"}}>
            <strong>CRAN CAPITAL PORTFOLIO</strong> <br />
            CRAN CAPITAL Portfolio is a tool for investment in portfolios of digital assets. CRAN CAPITAL offers portfolios with various indicators of risk/return ratio. Any user with or without experience could select a portfolio.
          </p>
      </div>
    </div>
  </div>
</div>

  )
}

export default About;