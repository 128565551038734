import React from 'react'

const Youtube = () => {
    return (
<div className="process">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-10 m-auto wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="upper-content text-center">
          <h4 className="lasthead">Watch this video presentation to know more about our trading strategies</h4>
          <h2 className="title">Want to know how we make profits?</h2>
          <p className="text">Let's watch the video below </p>
        </div>
      </div>
      <div className="col-md-12 mb-4 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="videoo">
          <img style={{borderRadius: 25}} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1652428331/videopeopleimage.jpg" alt="user" className="video-bg" />
          <div className="video-box">
            <div className="video-img">
              <a className="youtube-video mfp-iframe video-play-btn video-icon" href="https://www.youtube.com/watch?v=MhwrQpXQq4I">
                <i className="fas fa-play" />
              </a>
            </div>
            <div className="video-text">
              <p className="text">Watch Video Now</p>
              <span><i className="far fa-clock" /> 5:50 minutes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
)
}

export default Youtube;