import React from 'react';
import { Link } from "react-router-dom";

const Hero = () => {

  return (
    <>
 <div>
  <div className="banner" id="home" style={{backgroundColor: 'rgba(7, 7, 7, 0.95)', backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1657782212/00cranbanner.png")'}}>
    <div className="hero-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="banner-content">
              <h1 style={{color: '#1208c9'}} className="head wow fadeInUp mt-n4" data-wow-duration="0.3s" data-wow-delay="0.3s">Platform for Generating Income from Digital Assets</h1>
              <p className="text wow fadeInUp text-dark" data-wow-duration="0.3s" data-wow-delay="0.3s">
                The mission of REVAMP BOT Platform is to provide the user with simple, safe, reliable and effective tools for digital assets’ management for the purpose of generating income
              </p>
              <div className="d-flex">
                <a href="https://dashboard.revamp-bot.com/register" className="button button-1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">Register</a> 
                <a href="https://dashboard.revamp-bot.com/" className="button button-1 wow " style={{marginLeft: 9}} data-wow-duration="0.3s" data-wow-delay="0.3s">Login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div id="particles-js" className="position-absolute" style={{top: 0, right: 0, bottom: 10, left: 0}} /> */}
</div>
</>
   
  )
}

export default Hero