import React from 'react'

const Deposit = () => {
    

    return (
       <div style={{marginTop: '-150px'}} className="deposit" id="deposit">
  <div className="sape">
    <img src="assets/img/rdot-1.png" alt="user" className="rdot-1" />
    <img src="assets/img/rdot-2.png" alt="user" className="rdot-2" />
    <img src="assets/img/rdot-3.png" alt="user" className="rdot-3" />
  </div>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="offer">
          <h3 className="subtitle">We are Offering</h3>
          <div className="offer-box" style={{backgroundColor: '#024502', backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1657038357/offer-box-bg.png")'}}>
            <div className="offer-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <div className="icon">
                <img src="assets/img/offer-icon-1.png" alt="user" className="offer-icon" />
              </div>
              <div className="content">
                <h2 className="pursent">5.00%</h2>
                <p className="text">ROI MODEL</p>
              </div>
            </div>
            <div className="arrow wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <img src="assets/img/arrow-right.png" alt="user" className="arrow-pic" />
            </div>
            <div className="offer-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <div className="icon">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1657038357/offer-icon-2.png" alt="user" className="offer-icon" />
              </div>
              <div className="content">
                <h2 className="pursent">17.00%</h2>
                <p className="text">ROI MODEL</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}

export default Deposit;