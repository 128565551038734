import React from 'react'

const Affiliate = () => {
  return (
    <div>
  <div className="banner affiliate" id="home" style={{backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1657869383/bannerreferral.jpg")'}}>
    {/* hero-area Start */}
    <div className="hero-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-8 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <div className="banner-content">
              <h2 className="title">Affiliate</h2>
              <ul className="breadcrumb">
                <li>
                  <a href="{{url('/')}}">Home</a> <i className="flaticon-airplane49" />
                </li>
                <li>
                  Affiliate
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Affiliate-programe Start */}
  <section className="affiliate-programe">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-7 ">
          <div className="upper-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <h4 className="lasthead">What You’ll Get As</h4>
            <h2 className="title">Affiliate Program</h2>
            <p className="text">
              We give you the opportunity to earn money by recommending our website to others. You can
              start earning money even if you do not invest.
            </p>
          </div>
          <div className="affiliate-box" style={{background: 'url("assets/img/map.png")'}}>
            <div className="affiliate-wrapper">
              <div className="affiliate-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                <div className="affiliate-inner">
                  <div className="affiliate-thumb">
                    <h3 className="subtitle">5</h3>
                    <span className="remainder">%</span>
                    <span className="cont">1st</span>
                  </div>
                </div>
              </div>
              <div className="affiliate-item cl-two wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
                <div className="affiliate-inner">
                  <div className="affiliate-thumb">
                    <h3 className="subtitle">5</h3>
                    <span className="remainder">%</span>
                    <span className="cont">2nd</span>
                  </div>
                </div>
              </div>
              <div className="affiliate-item cl-three wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <div className="affiliate-inner">
                  <div className="affiliate-thumb">
                    <h3 className="subtitle">10</h3>
                    <span className="remainder">%</span>
                    <span className="cont">3rd</span>
                  </div>
                </div>
              </div>
              <div className="affiliate-item cl-three wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <div className="affiliate-inner">
                  <div className="affiliate-thumb">
                    <h3 className="subtitle">10</h3>
                    <span className="remainder">%</span>
                    <span className="cont">4th</span>
                  </div>
                </div>
              </div>
              <div className="affiliate-item cl-three wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <div className="affiliate-inner">
                  <div className="affiliate-thumb">
                    <h3 className="subtitle">10</h3>
                    <span className="remainder">%</span>
                    <span className="cont">5th</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="affiliate-bottom wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <p className="text">Make money with Revamp Bots</p>
              <a href="https://dashboard.revamp-bot.com/" className="custom-button button-1">
                learn more <i className="flaticon-right" />
              </a> 
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-lg-block d-none">
          <div className="afiliate-thumb">
            <img src="assets/img/affiliate.png" alt="affiliate" className="affi-1" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Counter Start */}
  @include('inc.counter')
  {/* How-Section Start */}
  <section className="how-section" style={{backgroundImage: 'url("assets/img/affiliate-bg.png")'}}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
          <div className="upper-content text-center">
            <h4 className="lasthead">Here’s how it works</h4>
            <h2 className="title">Getting Started? It’s Simple</h2>
            <p className="text">
              The affiliate program is our special feature for loyal Investors. Invite users and earn 5% to 10%
              of their investments!
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-30-none">
        <div className="col-md-6 col-lg-4 col-sm-10 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
          <div className="how-item">
            <div className="how-thumb-area">
              <div className="how-thumb">
                <img src="assets/img/how4.png" alt="how" />
              </div>
            </div>
            <div className="how-content">
              <h4 className="lasthead">Join Program</h4>
              <a href="https://dashboard.revamp-bot.com/">Join Now <i className="fas fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-sm-10 wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
          <div className="how-item active">
            <div className="how-thumb-area">
              <div className="how-thumb">
                <img src="assets/img/how5.png" alt="how" />
              </div>
            </div>
            <div className="how-content">
              <h4 className="lasthead">Promote</h4>
              <a href="https://dashboard.revamp-bot.com/">Tracking Link <i className="fas fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-sm-10 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
          <div className="how-item">
            <div className="how-thumb-area">
              <div className="how-thumb">
                <img src="assets/img/how6.png" alt="how" />
              </div>
            </div>
            <div className="how-content">
              <h4 className="lasthead">Earn</h4>
              <a href="https://dashboard.revamp-bot.com/">Commission Model <i className="fas fa-arrow-right" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Why-Affiliate-Section Start */}
  <section className="why-affiliate-section">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6">
          <div className="why-affiliate-content">
            <div className="upper-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <h4 className="lasthead">Why Should You</h4>
              <h2 className="title">Join affiliate?</h2>
              <p className="text">
                The affiliate program is our special feature for loyal Investors.
              </p>
            </div>
            <div className="why-area">
              <div className="why-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                <div className="why-inner">
                  <div className="why-thumb">
                    <img src="assets/img/why1.png" alt="why" />
                  </div>
                  <div className="why-content">
                    <h6 className="title">Joining free </h6>
                  </div>
                </div>
              </div>
              <div className="why-item wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
                <div className="why-inner">
                  <div className="why-thumb">
                    <img src="assets/img/why2.png" alt="why" />
                  </div>
                  <div className="why-content">
                    <h6 className="title">Instant Payout</h6>
                  </div>
                </div>
              </div>
              <div className="why-item wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <div className="why-inner">
                  <div className="why-thumb">
                    <img src="assets/img/why3.png" alt="why" />
                  </div>
                  <div className="why-content">
                    <h6 className="title">Performance Bonues</h6>
                  </div>
                </div>
              </div>
              <div className="why-item wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.6s">
                <div className="why-inner">
                  <div className="why-thumb">
                    <img src="assets/img/why4.png" alt="why" />
                  </div>
                  <div className="why-content">
                    <h6 className="title">Unlimited affiliates</h6>
                  </div>
                </div>
              </div>
            </div>
            <a href="https://dashboard.revamp-bot.com/" className="custom-button button-1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">join Now!</a>
          </div>
        </div>
        <div className="col-lg-6 d-lg-block d-none">
          <div className="why--thumb wow fadeInRight" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <img src="assets/img/how.png" alt="why" className="why" />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default Affiliate