import React, {Fragment, useEffect, useState} from 'react'
import apiClient from "../../http-common";
import { useQuery } from "react-query";

 const CoinList = () => {
    const initialCoins = {
        coinsData: []
    }
    const [coins, setCoins] = useState(initialCoins);

    const { isLoading: isLoadingCOins, refetch: loadCoins } = useQuery(
        "coin-rates",
        async () => {
          return await apiClient.get("/api/coin-rates");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            setCoins({...coins, coinsData:res.data.coins});
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(()=>{
        loadCoins();
      }, [])
      const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

      const {coinsData} = coins;
    return (
        <div>
        {isLoadingCOins ? <span>loading...</span> :
    
<div className="compare" id="currency" style={{marginTop: '-80px', backgroundColor: "#fafafc"}}>
  {coinsData.length > 0 &&
    <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="coin-box owl-carousel owl-theme d-flex justify-content-center">
            {coinsData.map(coin =>(
                <Fragment key={coin.id}>
                    {coin.symbol === 'BTC' && 
                    <div className="compare-box item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                        <div className="single bg-white">
                        <div className="icon">
                            <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1657089112/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2.png" alt="coin" className="coin-icon" />
                            <p className="text">Bitcoin <span>BTC</span></p>
                        </div>
                        <h4 className="lasthead">{getAmount(coin.priceUsd)}
                            <Fragment>
                                {parseFloat(coin.changePercent24Hr) > 0 ? 
                                <span>+{parseFloat(coin.changePercent24Hr).toFixed(2)}%</span>
                                :
                                <span className="bg-danger text-white bg-opacity-25">{parseFloat(coin.changePercent24Hr).toFixed(2)}%</span>
                                }
                            </Fragment>
                        </h4>
                        <p className="text">Price (24 hours)</p>
                        </div>
                    </div>
                    }


                {coin.symbol === 'ETH' && 
                    <div className="compare-box item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                        <div className="single bg-white">
                        <div className="icon">
                            <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1657089159/4113b082d21cc5fab17fc8f2d19fb996165bcce635e6900f7fc2d57c4ef33ae9.png" alt="coin" className="coin-icon" />
                            <p className="text">Ethereum <span>ETH</span></p>
                        </div>
                        <h4 className="lasthead">{getAmount(coin.priceUsd)}
                            <Fragment>
                                {parseFloat(coin.changePercent24Hr) > 0 ? 
                                <span>+{parseFloat(coin.changePercent24Hr).toFixed(2)}%</span>
                                :
                                <span className="bg-danger text-white bg-opacity-25">{parseFloat(coin.changePercent24Hr).toFixed(2)}%</span>
                                }
                            </Fragment>
                        </h4>
                        <p className="text">Price (24 hours)</p>
                        </div>
                    </div>
                    }

                {coin.symbol === 'LTC' && 
                    <div className="compare-box item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                        <div className="single bg-white">
                        <div className="icon">
                            <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1657089189/984a4fe2ba5b2c325c06e4c2f3ba3f1c1fef1f157edb3b8ebbfe234340a157a5.png" alt="coin" className="coin-icon" />
                            <p className="text">Litecoin <span>LTC</span></p>
                        </div>
                        <h4 className="lasthead">{getAmount(coin.priceUsd)}
                            <Fragment>
                                {parseFloat(coin.changePercent24Hr) > 0 ? 
                                <span>+{parseFloat(coin.changePercent24Hr).toFixed(2)}%</span>
                                :
                                <span className="bg-danger text-white bg-opacity-25">{parseFloat(coin.changePercent24Hr).toFixed(2)}%</span>
                                }
                            </Fragment>
                        </h4>
                        <p className="text">Price (24 hours)</p>
                        </div>
                    </div>
                }



                </Fragment>
            ))}
        </div>
      </div>
    </div>
  </div>
  }
</div>    
    }
</div>
    )
}

export default CoinList
