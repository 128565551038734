import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <div>
  <div className="footer" style={{backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1657038358/footer-bg.png")'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="0.2s" data-wow-delay="0.2s">
          <div className="footer-box">
            <Link to="/" className="logo">
              <img style={{height: 53, width: 200}} src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1660226346/revampgreener.png" alt="logo" />
            </Link>
            <p className="text">
              Mentioned income cannot be treated as an official offer, pledge or commitment. Mentioned amount is based on approximate calculations due to the current level of trading market indicators and stated for the purposes of displaying the approximate efficiency.
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
          <div className="footer-box">
            <h4 className="lasthead">Company</h4>
            <ul className="footer-link">
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/affiliate">Affiliate</Link></li>
              <li><Link to="https://dashboard.revamp-bot.com/">Register</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
          <div className="footer-box">
            <h4 className="lasthead">Support</h4>
            <ul className="footer-link">
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/faq">Knowledge Base</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
          <div className="footer-box">
            <h4 className="lasthead">Policy</h4>
            <ul className="footer-link">
              <li><Link to="/terms">Terms of use</Link></li>
              <li><Link to="/terms">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.6s">
          <div className="footer-box">
            <h4 className="lasthead">Contacts</h4>
            <ul className="footer-link">
              <li>support@revamp-bot.com </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 wow fadeInUp" data-wow-duration="0.2s" data-wow-delay="0.2s">
          <div className="raka" />
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
          <div className="footer-bottom">
            <p className="text">Copyright 2022.All Rights Reserved By <Link to="/">REVAMP BOTS
              </Link> </p>
          </div>
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
          <div className="footer-bottom">
            <div className="social-style">
              <Link to="#"><i className="fab fa-facebook-f"/></Link>
              <Link to="#"><i className="fab fa-twitter"/></Link>
              <Link to="#"><i className="fab fa-pinterest-p"/></Link>
              <Link to="#"><i className="fab fa-google-plus-g"/></Link>
              <Link to="#"><i className="fab fa-instagram"/></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span id="google_translate_element" />
  </div>
</div>  
   
  )
}

export default Footer