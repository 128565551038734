import React from 'react'

const  Feature = () => {

    return (
       <div className="control mt-5">
  <div className="container">
    <div className="row">
      <div className="col-12 text-center wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="section-head">
          <h2 className="title">Take Control of Your Earnings</h2>
          <p className="text">
            Our system is designed with the best algorithms to help you earn without stress
          </p>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 text-center wow fadeInDown" data-wow-duration="0.3s" data-wow-delay="0.3s">
        <div className="control-box">
          <div className="tumb">
            <img src="assets/img/control-1.png" alt="mark"  />
          </div>
          <h3 className="subtitle">Your Earnings</h3>
          <p className="text">
            All trade earnings are monitored from your dashboard.
          </p>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 text-center wow fadeInDown" data-wow-duration="0.4s" data-wow-delay="0.3s">
        <div className="control-box">
          <div className="tumb">
            <img src="assets/img/control-2.png" alt="mark"  />
          </div>
          <h3 className="subtitle">Easy Payments </h3>
          <p className="text">
            Our payments is swift, there are no delays in processing your payments
          </p>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 text-center wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">
        <div className="control-box">
          <div className="tumb">
            <img src="assets/img/control-3.png" alt="mark" />
          </div>
          <h3 className="subtitle">Track Activities</h3>
          <p className="text">
            All activities of your account are available in your dashboard
          </p>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 text-center wow fadeInDown" data-wow-duration="0.6s" data-wow-delay="0.3s">
        <div className="control-box">
          <div className="tumb">
            <img src="assets/img/control-4.png" alt="mark"  />
          </div>
          <h3 className="subtitle">24/7 Support</h3>
          <p className="text">
            We have the best support team active 24/7 to assist you
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

       
    )
}

export default Feature;