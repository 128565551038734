import React from 'react'

const Plans = () => {
    

    return (
        <div className="container-fluid" style={{position: 'relative', zIndex: 30, backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1659907164/footerbank.png)"}}>
  <div className="container">

    <div className="col-12">
      <h2 className="title text-center text-white pt-4">OUR INVESTMENT PLANS </h2>
      <p className="text-center text-white p-4">Buy our shares and earn daily dividends from the profits generated daily</p>
    </div>
    <div className="d-flex justify-content-between mb-7">
      <div className="text-center m-2 p-3 rounded rounded-lg" style={{boxShadow: 'rgba(255, 255, 255, 0.99) 0px  5px 10px 0px', borderradius: 10, backgroundcolor: 'rgb(255, 255, 255)'}}>
        <div className="icon text-center"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-3.png" style={{height: 50}} alt="icon-image" /></div>
        <div className="text-center w-100 text-white mt-2 p-1 rounded" style={{background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}><h4 className="percentage text-center mt-1 p-2 text-white fs-2 mb-1">15% Daily ROI</h4></div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Min Amt: $50
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Max Amt: $499
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> 5% Ref Bonus
          </div>
        </div>
        <div className="package-name m-2 text-white text-center">24 Hours, 2 Days and 1 Week contract durations</div>
        <div className="text-center">
          <a className="btn text-white" href="https://dashboard.revamp-bot.com/" style={{boxShadow: '0px 5px 15px 0px rgb(230 97 43 / 40%)', background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}>invest now
          </a>
        </div>
      </div>
      <div className="text-center m-2 p-3 rounded rounded-lg" style={{boxShadow: 'rgba(255, 255, 255, 0.99) 0px  5px 10px 0px', borderradius: 10, backgroundcolor: 'rgb(255, 255, 255)'}}>
        <div className="icon text-center"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-3.png" style={{height: 50}} alt="icon-image" /></div>
        <div className="text-center w-100 text-white mt-2 p-1 rounded" style={{background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}><h4 className="percentage text-center mt-1 p-2 text-white fs-2 mb-1">21% Daily ROI</h4></div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Min Amt: $500
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Max Amt: $4,999
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> 5% Ref Bonus
          </div>
        </div>
        <div className="package-name m-2 text-white">3 Days and 1 Week contract duration</div>
        <div className="text-center">
          <a className="btn text-white" href="https://dashboard.revamp-bot.com/" style={{boxShadow: '0px 5px 15px 0px rgb(230 97 43 / 40%)', background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}>invest now
          </a>
        </div>
      </div>
      <div className="text-center m-2 p-3 rounded rounded-lg" style={{boxShadow: 'rgba(255, 255, 255, 0.99) 0px  5px 10px 0px', borderradius: 10, backgroundcolor: 'rgb(255, 255, 255)'}}>
        <div className="icon text-center"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-3.png" style={{height: 50}} alt="icon-image" /></div>
        <div className="text-center w-100 text-white mt-2 p-1 rounded" style={{background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}><h4 className="percentage text-center mt-1 p-2 text-white fs-2 mb-1">40% Daily ROI</h4></div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Min Amt: $5,000
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Max Amt: $9,999
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> 5% Ref Bonus
          </div>
        </div>
        <div className="package-name m-2 text-white">1 week and 2 Weeks contract duration</div>
        <div className="text-center">
          <a className="btn text-white" href="https://dashboard.revamp-bot.com/" style={{boxShadow: '0px 5px 15px 0px rgb(230 97 43 / 40%)', background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}>invest now
          </a>
        </div>
      </div>
      <div className="text-center m-2 p-3 rounded rounded-lg" style={{boxShadow: 'rgba(255, 255, 255, 0.99) 0px  5px 10px 0px', borderradius: 10, backgroundcolor: 'rgb(255, 255, 255)'}}>
        <div className="icon text-center"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659906460/f-item-3.png" style={{height: 50}} alt="icon-image" /></div>
        <div className="text-center w-100 text-white mt-2 p-1 rounded" style={{background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}><h4 className="percentage text-center mt-1 p-2 text-white fs-2 mb-1">75% Daily ROI</h4></div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Min Amt: $10,000
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> Max Amt: 500,000
          </div>
        </div>
        <div className="media d-block font-size-1 text-body mb-2">
          <div className="media-body text-white">
            <i className="fas fa-check-circle text-success mt-1 mr-2 text-left" /> 5% Ref Bonus
          </div>
        </div>
        <div className="package-name m-2 text-white">2 weeks and 1 Month contract duration</div>
        <div className="text-center">
          <a className="btn text-white" href="https://dashboard.revamp-bot.com/" style={{boxShadow: '0px 5px 15px 0px rgb(230 97 43 / 40%)', background: 'linear-gradient(90deg, rgba(67,16,197,1) 0%, rgba(66,13,214,1) 35%, rgba(11,26,183,1) 100%)'}}>invest now
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}

export default Plans;

