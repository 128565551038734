import React from 'react'

const Feature2 = () => {
  return (
    <section className="why-affiliate-section" style={{marginTop: "-190px"}}>
  <div className="container">
    <div className="row justify-content-between align-items-center">
      <div className="col-lg-6">
        <div className="why-affiliate-content">
          <div className="upper-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <h4 className="lasthead">Why Should You Join Us</h4>
            <h2 className="title">On-the-Go and Seamless trading integration</h2>
            <p className="text">
              With the Revamp Bot App, your money works for you
            </p>
          </div>
          <div className="why-area">
            <div className="why-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why1.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title">Joining free </h6>
                </div>
              </div>
            </div>
            <div className="why-item wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.4s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why2.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title">Quick Lock</h6>
                </div>
              </div>
            </div>
            <div className="why-item wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why3.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title"> Bonues</h6>
                </div>
              </div>
            </div>
            <div className="why-item wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.6s">
              <div className="why-inner">
                <div className="why-thumb">
                  <img src="assets/img/why4.png" alt="why" />
                </div>
                <div className="why-content">
                  <h6 className="title">Unlimited Transactions</h6>
                </div>
              </div>
            </div>
          </div>
          <a href="https://dashboard.revamp-bot.com/" className="custom-button button-1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">Open account</a>
        </div>
      </div>
      <div className="col-lg-6 d-lg-block d-none">
        <div className="why--thumb wow fadeInRight" data-wow-duration="0.3s" data-wow-delay="0.3s">
          <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659789691/banner-img1.png" alt="why" className="why" />
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Feature2