import React from 'react'

const Contact = () => {
  return (
    <div>
  <div className="banner contact" id="home" style={{backgroundImage: 'url("https://res.cloudinary.com/dzxyvdq14/image/upload/v1657704090/crancapitabanner1.png")'}}>
    {/* hero-area Start */}
    <div className="hero-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-8 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
            <div className="banner-content">
              <h2 className="title text-dark">Contact</h2>
              <ul className="breadcrumb text-dark">
                <li>
                  <a href="/">Home</a> <i className="flaticon-airplane49" />
                </li>
                <li>
                </li><li>
                  Contact
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Contact-section Start */}
  <section className="contact-section">
    <div className="container">
      <div className="row justify-content-center justify-content-lg-between">
        <div className="col-lg-12">
          <div className="contact-content">
            <div className="section-header wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
              <h3 className="subtitle">Have questions?</h3>
              <p className="text">Have questions about payments or price
                plans? We have answers!</p>
              <a href="faq.html">Read F.A.Q <i className="fas fa-angle-right" /></a>
            </div>
            <div className="contact-area">
              <div className="contact-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                <div className="contact-thumb">
                  <img src="assets/img/contact1.png" alt="contact" />
                </div>
                <div className="contact-contact">
                  <h4 className="lasthead">Email Us</h4>
                  <a href="Mailto:support@revamp-bot.com ">support@revamp-bot.com </a>
                </div>
              </div>
               <div className="contact-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                <div className="contact-thumb">
                  <img src="assets/img/contact2.png" alt="contact" />
                </div>
                {/* <div className="contact-contact">
                  <h4 className="lasthead">Call Us</h4>
                  <a href="Tel:565656855">+1 (987) 664-32-11</a>
                </div> */}
              </div> 
              <div className="contact-item wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                <div className="contact-thumb">
                  <img src="assets/img/contact3.png" alt="contact" />
                </div>
                <div className="contact-contact">
                  <h4 className="lasthead">Visit Us</h4>
                  <p>Arnella Court, Queens Road, Farnborough, England, GU14 6JR</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default Contact